<template>
  <div class="main-body" id="news">
    <div class="flex-wrap">
      <div class="tab-nav">
        <ul>
          <li
            :class="[tabIndex === index ? 'active' : '']"
            @click="cutTabs(item, index)"
            v-for="(item, index) in typeList"
            :key="index"
          >
            {{ item.typeName }}
          </li>
          <li
            :class="[tabIndex === 99 ? 'active' : '']"
            @click="
              () => {
                tabIndex = 99
              }
            "
          >
            平台活动
          </li>
        </ul>
      </div>
      <div class="tab-content flex-con">
        <div v-show="tabIndex != 99">
          <div class="content-top">
            <div class="example" v-if="topLoading">
              <a-spin size="large" />
            </div>
            <ul class="flex-wrap" v-else>
              <li
                class="flex-con"
                v-for="(item, index) in indexHotInfoList.slice(0, 3)"
                :key="index"
                @click="openNewsDetails(item)"
              >
                <div class="news-img">
                  <img
                    :src="item.indexPicture"
                    alt=""
                    width="238"
                    height="166"
                  />
                </div>
                <div class="title t-hide">{{ item.title }}</div>
                <div class="g-content">
                  {{ getText(item.content).substring(0, 30) }}...
                </div>
              </li>
            </ul>
          </div>

          <div
            v-show="tabIndex === index"
            v-for="(item, index) in typeList"
            :key="index"
          >
            <news ref="news" />
          </div>
        </div>

        <div v-show="tabIndex === 99">
          <activity />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import News from './News/News'
import Activity from './Activity/Activity'
import { yqmsInformationTypeListAll, publicInfoIndex } from '@/api/api'
export default {
  components: {
    News,
    Activity,
  },
  data() {
    return {
      tabIndex: 0,
      typeList: [],
      topLoading: false,
      indexHotInfoList: [],
    }
  },
  created() {
    this.getTypeAll()
    this.getInfo()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != 'NewsDetails' && to.name != 'ActivityDetails') {
      this.tabIndex = 0
    }
    next()
  },
  mounted() {
    if (this.$route.params.index || this.$route.params.index === 0) {
      this.$nextTick(() => {
        document.querySelector('#news').scrollIntoView(true)
        if (this.$route.params.index === 0) {
          this.tabIndex = 1
        } else if (this.$route.params.index === 4) {
          this.tabIndex = 99
        } else if (this.$route.params.index === 7) {
          this.tabIndex = 2
        }
      })
    }
  },
  activated() {
    if (this.$route.params.index || this.$route.params.index === 0) {
      this.$nextTick(() => {
        document.querySelector('#news').scrollIntoView(true)
        if (this.$route.params.index === 0) {
          this.tabIndex = 1
        } else if (this.$route.params.index === 4) {
          this.tabIndex = 99
        } else if (this.$route.params.index === 7) {
          this.tabIndex = 2
        }
      })
    }
    // this.getInfo()
  },
  methods: {
    async getTypeAll() {
      this.loading = true
      try {
        const res = await yqmsInformationTypeListAll()
        if (res.success) {
          this.typeList = res.result
          setTimeout(() => {
            this.$refs.news[this.tabIndex].getInfoList(
              this.typeList[this.tabIndex].id
            )
          }, 300)
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    async getInfo() {
      this.topLoading = true
      try {
        const res = await publicInfoIndex()
        if (res.code == 200) {
          this.indexHotInfoList = res.result.indexHotInfoList
          this.topLoading = false
        } else {
          this.$message.error(res.message)
          this.topLoading = false
        }
      } catch (err) {
        this.$message.error(err.message)
        this.topLoading = false
      }
    },
    cutTabs(item, index) {
      this.tabIndex = index
      this.$refs.news[index].getInfoList(item.id)
    },
    getText(str) {
      return str
        .replace(/<[^<>]+>/g, '')
        .replace(/&nbsp;/gi, '')
        .replace(/&ldquo;/gi, '')
        .replace(/&rdquo;/gi, '')
        .replace(/&rdquo;/gi, '')
        .replace(/&mdash;/gi, '')
        .replace(/&middot;/gi, '')
        .replace(/\s/g, '')
    },
    openNewsDetails(data) {
      this.$router.push({
        name: 'NewsDetails',
        params: {
          data: encodeURIComponent(JSON.stringify(data)),
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.main-body {
  background: #f1f1f1;
  > div {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;

    .tab-nav {
      ul {
        li {
          list-style: none;
          font-size: 18px;
          font-weight: 400;
          color: #555555;
          background: #fff;
          padding: 26px 145px;
          border-bottom: 1px dashed #ccc;
          cursor: pointer;

          &.active {
            color: #1285d6;
            background: rgba(18, 133, 214, 0.2);
          }

          &:last-child {
            border: none;
          }

          &:hover {
            color: #1285d6;
            background: rgba(18, 133, 214, 0.2);
          }
        }
      }
    }

    .tab-content {
      margin-left: 25px;

      .content-top {
        background: #fff;
        margin-left: 23px;
        margin-bottom: 20px;
        ul {
          li {
            padding: 30px 12px;
            cursor: pointer;

            .news-img {
              text-align: center;
            }

            .title {
              font-weight: 400;
              color: #666666;
              margin: 15px 0 9px 0;
            }

            .g-content {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
          }
        }

        .example {
          text-align: center;
          padding: 100px 0;
        }
      }
    }
  }
}
</style>
