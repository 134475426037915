<template>
  <div class="content flex-con">
    <div class="content-bottom">
      <div class="example" v-if="loading">
        <a-spin size="large" />
      </div>
      <div
        class="flex-wrap flex-vertical flex-center not-img"
        v-else-if="infoList.length <= 0 && !loading"
      >
        <img src="@/assets/zwsj.png" alt="" />
        <div>暂无数据</div>
      </div>
      <div>
        <ul>
          <li
            class="flex-wrap flex-h-center"
            @click="openNewsDetails(item)"
            v-for="(item, index) in infoList"
            :key="index"
          >
            <div class="date">
              <div>{{ getDate(item.createTime).day }}</div>
              <div>{{ getDate(item.createTime).yearMonth }}</div>
            </div>
            <div class="flex-con news">
              <p class="title">{{ item.title }}</p>
              <p class="news-content">
                {{ getText(item.content).substring(0, 90) }}...
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="paging">
        <a-pagination
          v-model="page.current"
          :pageSize="page.pageSize"
          :total="page.total"
          hideOnSinglePage
          @change="changePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { yqmsInformationList } from '@/api/api'

export default {
  data() {
    return {
      indexHotInfoList: [],
      page: {
        pageSize: 5,
        current: 1,
        total: 0,
      },
      infoList: [],
      typeId: '',
      loading: false,
      topLoading: false,
    }
  },
  methods: {
    async getInfoList(typeId) {
      this.loading = true
      this.typeId = typeId
      try {
        const params = {
          pageNo: this.page.current,
          pageSize: this.page.pageSize,
          typeId: typeId,
          notStatus: 0,
        }
        const res = await yqmsInformationList(params)
        if (res.code == 200) {
          this.infoList = res.result.records
          this.page.current = res.result.current
          this.page.total = res.result.total
          this.loading = false
        } else {
          this.$message.error(res.message)
          this.loading = false
        }
      } catch (err) {
        this.$message.error(err.message)
        this.loading = false
      }
    },
    changePage(page) {
      this.infoList = []
      this.page.current = page
      this.getInfoList(this.typeId)
    },
    getText(str) {
      return str
        .replace(/<[^<>]+>/g, '')
        .replace(/&nbsp;/gi, '')
        .replace(/&ldquo;/gi, '')
        .replace(/&rdquo;/gi, '')
        .replace(/&rdquo;/gi, '')
        .replace(/&mdash;/gi, '')
        .replace(/&middot;/gi, '')
        .replace(/\s/g, '')
    },
    getDate(str) {
      const date = str.split(' ')[0]
      const year = date.split('-')[0]
      const month = date.split('-')[1]
      const day = date.split('-')[2]
      return {
        day: day,
        yearMonth: year + '/' + month,
      }
    },

    openNewsDetails(data) {
      this.$router.push({
        name: 'NewsDetails',
        params: {
          data: encodeURIComponent(JSON.stringify(data)),
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  margin-left: 23px;

  .content-bottom {
    background: #fff;
    padding: 18px 0;
    > div:nth-child(1) {
      ul {
        li {
          padding: 12px 26px;
          cursor: pointer;
          .date {
            width: 99px;
            height: 99px;
            background: rgba(208, 231, 247, 0.7);
            border-radius: 5px;
            color: #1285d6;

            > div {
              text-align: center;
              &:nth-child(1) {
                font-size: 42px;
                font-weight: 500;
              }

              &:nth-child(2) {
                font-size: 18px;
                font-weight: 400;
              }
            }
          }

          .news {
            margin-left: 18px;
            .title {
              font-weight: 400;
              color: #555555;
            }

            .news-content {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              margin-top: 22px;
            }
          }
        }
      }
    }

    .paging {
      text-align: right;
      padding: 0 26px;
    }

    .example {
      text-align: center;
      padding: 200px 0;
    }
  }
}
</style>
