<template>
  <div class="main-body">
    <div>
      <div>
        <div class="top flex-wrap flex-h-center">
          <div class="horn-title-style">{{ infoData.typeName }}</div>
          <div class="flex-con flex-wrap flex-h-center flex-between">
            <div class="menu-nav">
              新闻资讯<a-icon type="right" /> {{ infoData.typeName }}
            </div>
            <div class="flex-wrap flex-h-center">
              <div
                class="back-btn"
                v-if="infoData.processModelId"
                @click="openApplyModal"
              >
                申请
              </div>
              <div class="back-btn" @click="$router.go(-1)">返回</div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title">{{ infoData.title }}</div>
          <div class="time">发布时间：{{ infoData.createTime }}</div>
          <div v-html="infoData.content"></div>
        </div>
      </div>
    </div>
    <apply-modal ref="applyModal" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getRoleListByModelId } from '@/api/api'
import ApplyModal from '@/components/Modal/ApplyModal'
export default {
  components: {
    ApplyModal,
  },
  data() {
    return {
      infoData: {},
      processRoles: '',
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      authMessage: (state) => state.auth.authMessage,
      authInfo: (state) => state.auth.authInfo,
      role: (state) => state.user.role,
    }),
  },
  created() {
    if (this.$route.params.data) {
      this.infoData = JSON.parse(decodeURIComponent(this.$route.params.data))
      if (this.infoData.processModelId && this.token) {
        this.getRoleIds(this.infoData.processModelId)
      }
    }
  },
  watch: {
    token(val) {
      if (val) {
        this.getRoleIds(this.infoData.processModelId)
      }
    },
  },
  methods: {
    getRoleIds(id) {
      getRoleListByModelId(id)
        .then((res) => {
          if (res.success) {
            let ids = ''
            res.result.forEach((item) => {
              ids += item.id + ','
            })
            if (ids.endsWith(',')) {
              ids = ids.substring(0, ids.length - 1)
            }
            this.processRoles = ids
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },
    openApplyModal() {
      if (this.token) {
        let flag = false
        this.role.forEach((item) => {
          if (this.processRoles.indexOf(item.id) != -1) {
            flag = true
            return
          }
        })
        console.log('角色id', this.role)
        console.log('流程id', this.processRoles)
        console.log('flag', flag)
        if (flag) {
          if (
            this.infoData.processModelId ==
            '4721fd4a-308b-11ec-bf6e-00163e08697a'
          ) {
            // this.$router.push({
            //   name: 'ConferenceRoom',
            //   params: {
            //     data: window.btoa(encodeURIComponent(JSON.stringify(data))),
            //   },
            // })
          } else {
            this.$refs.applyModal.showModal(this.infoData.processModelId)
          }
        } else {
          this.$auth(this.authMessage)
        }
      } else {
        this.$message.warning('未登录，请先登录')
        this.$login()
      }
    },
  },
}
</script>
<style lang="less" scoped>
p {
  margin: 0;
  padding: 0;
}
.main-body {
  background: #f4f4f4;

  > div {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    > div {
      background: #fff;
      .top {
        border-bottom: 1px solid #e5e5e5;

        .menu-nav {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          margin-left: 17px;
        }

        .back-btn {
          width: 109px;
          height: 39px;
          line-height: 39px;
          background: #1285d6;
          border-radius: 20px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          margin-right: 34px;
          cursor: pointer;
        }
      }
    }
  }

  .horn-title-style {
    width: 160px;
    height: 55px;
    line-height: 55px;
    background-color: #1285d6;
    position: relative;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: rgb(18, 133, 214);
      top: 100%;
      left: 50%;
      position: absolute;
      margin-left: -12px;
    }
  }

  .content {
    min-height: 600px;
    padding: 50px 30px;
    font-size: 16px;
    color: #333;
    line-height: 2;
    .title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }

    .time {
      text-align: center;
      font-size: 14px;
      color: #666;
      margin: 5px 0 20px 0;
    }
  }
}
</style>
