<template>
  <div>
    <a-modal
      :width="1000"
      v-model="visible"
      :title="modalInfo.name"
      @ok="handleOk"
    >
      <div class="form-content">
        <k-form-build ref="kfb" :value="kfdJsonData" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { getModelById } from '@/api/api'
import { KFormBuild } from '@/components/k-form-design/index'
import {
  saveOrUpdate,
  getFormByProcessDefinitionId,
  startInstance,
} from '@/api/process'
export default {
  components: {
    KFormBuild,
  },
  data() {
    return {
      visible: false,
      modalInfo: {},
      kfdJsonData: {},
      formId: '',
    }
  },
  methods: {
    showModal(modalId) {
      this.getModelInfo(modalId)
      this.visible = true
    },
    //根据modalId获取流程数据
    async getModelInfo(id) {
      try {
        const res = await getModelById(id)
        if (res.success) {
          console.log('流程数据', res.result)
          this.modalInfo = res.result
          this.getProcessData()
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },

    getProcessData() {
      const params = {
        modelId: this.modalInfo.id,
        processDefinitionId: this.modalInfo.processDefinitionId,
      }
      // 根据modelId请求流程最新的发布数据
      getFormByProcessDefinitionId(params).then((res) => {
        if (res.success) {
          if (!res.result) {
            return this.$message.warning('该工单未设计表单')
          }
          this.formId = res.result.id
          this.kfdJsonData = JSON.parse(res.result.htmlJson)
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    async handleOk() {
      try {
        const formData = await this.$refs.kfb.getData()
        const res = await saveOrUpdate({
          modelId: this.modalInfo.id,
          formId: this.formId,
          processDefinitionId: this.modalInfo.processDefinitionId,
          formData: JSON.stringify(formData),
          type: 2,
        })
        if (res.success) {
          this.startInstance(res.result.id)
        } else {
          this.$message.warning(res.message)
        }
      } catch (err) {
        this.$message.warning(err.message)
      }
    },

    startInstance(id) {
      const params = {
        modelId: this.modalInfo.id,
        formDataId: id,
        processDefinitionId: this.modalInfo.processDefinitionId,
      }
      startInstance(params).then((res) => {
        if (res.success) {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    close() {
      this.modalInfo = {}
      this.kfdJsonData = {}
      this.formId = ''
      this.visible = false
    },
  },
}
</script>
