<template>
  <div class="content">
    <div class="example" v-if="loading">
      <a-spin size="large" />
    </div>
    <div
      class="flex-wrap flex-vertical flex-center not-img"
      v-else-if="activityList.length <= 0 && !loading"
    >
      <img src="@/assets/zwsj.png" alt="" />
      <div>暂无数据</div>
    </div>
    <ul class="flex-wrap flex-wrap-wrap">
      <li
        v-for="(item, index) in activityList"
        :key="index"
        @click="
          $router.push({ name: 'ActivityDetails', params: { id: item.id } })
        "
      >
        <div class="activity-img">
          <img :src="item.imageUrl" alt="" />
        </div>
        <div class="activity-info">
          <div class="name t-hide">{{ item.title }}</div>
          <div class="sponsor t-hide">主讲人：{{ item.mainSpeaker }}</div>
          <div class="date">日期：{{ item.startTime }}</div>
        </div>
      </li>
    </ul>

    <div class="paging">
      <a-pagination
        v-model="page.current"
        :pageSize="page.pageSize"
        :total="page.total"
        hideOnSinglePage
        @change="changePage"
      />
    </div>
  </div>
</template>
<script>
import { yqmsActivityList } from '@/api/api'
export default {
  components: {},
  data() {
    return {
      page: {
        pageSize: 9,
        current: 1,
        total: 0,
      },
      activityList: [],
      loading: false,
    }
  },
  activated() {
    this.getActivityList()
  },
  created() {
    this.getActivityList()
  },
  methods: {
    async getActivityList() {
      this.loading = true
      try {
        const params = {
          pageNo: this.page.current,
          pageSize: this.page.pageSize,
        }
        const res = await yqmsActivityList(params)
        if (res.success) {
          this.activityList = res.result.records
          this.page.current = res.result.current
          this.page.total = res.result.total
          this.loading = false
        } else {
          this.loading = false
          this.$message.error(res.message)
        }
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },

    changePage(page) {
      this.activityList = []
      this.page.current = page
      this.getActivityList()
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  background: #fff;
  min-height: 600px;
  padding: 20px 0;
  ul {
    padding: 0 20px;
    li {
      width: 235px;
      border: 1px solid #eee;
      border-radius: 5px;
      cursor: pointer;
      margin: 10px;
      .activity-img {
        width: 100%;
        img {
          width: 100%;
          height: 150px;
          border-radius: 5px 5px 0 0;
        }
      }

      .activity-info {
        padding: 0 11px;
        .name {
          font-size: 14px;
          color: #333333;
          margin-top: 12px;
        }

        .sponsor,
        .date {
          font-size: 12px;
          color: #999999;
        }

        .sponsor {
          margin: 12px 0 6px 0;
        }

        .date {
          margin-bottom: 9px;
        }
      }
    }
  }

  .paging {
    text-align: right;
    padding: 10px 10px 30px 0;
  }

  .example {
    text-align: center;
    padding: 200px 0;
  }
}
</style>
