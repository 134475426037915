<template>
  <div class="content">
    <div>
      <div class="title flex-wrap flex-h-center flex-between">
        <div class="horn-title-style">平台活动</div>
        <div class="btn-style" @click="$router.go(-1)">返回</div>
      </div>
      <div class="example" v-if="loading">
        <a-spin size="large" />
      </div>
      <div v-else>
        <div class="substance">
          <div class="survey flex-wrap flex-between">
            <ul>
              <li class="name">{{ activityData.title }}</li>
              <li>活动时间：{{ activityData.startTime }}</li>
              <li>活动地点：{{ activityData.address }}</li>
              <li>主讲人：{{ activityData.mainSpeaker }}</li>
            </ul>
            <div>
              <div class="btn-style" @click="signUp" v-if="token">立即报名</div>
            </div>
          </div>
        </div>
        <div class="concrete-content">
          <div>
            <div class="introduce">讲师介绍：</div>
            <div>
              {{ activityData.mainSpeakerAbout }}
            </div>
          </div>
          <div>
            <div class="introduce">活动介绍：</div>
            <div v-html="activityData.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { yqmsActivityQueryById, yqmsActivityApply } from '@/api/api'
export default {
  components: {},
  data() {
    return {
      loading: false,
      id: '',
      activityData: {},
    }
  },

  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.getYqmsActivityQueryById()
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
    }),
  },
  methods: {
    ...mapActions(['PersonalAuth']),
    ...mapGetters(['userInfo']),
    async getYqmsActivityQueryById() {
      this.loading = true
      try {
        const params = {
          id: this.id,
        }
        const res = await yqmsActivityQueryById(params)
        // console.log(res)
        if (res.success && res.code == 200) {
          this.activityData = res.result
          this.loading = false
        } else {
          this.loading = false
          this.$message.error(res.message)
        }
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },

    signUp() {
      if (this.activityData.type != 2) {
        this.sureSignUp()
      } else {
        const id = this.userInfo().id
        this.$store
          .dispatch('GetUserAuthStatus', id)
          .then((res) => {
            if (res.message == 101 || res.message == 201) {
              this.sureSignUp()
            } else {
              this.$message.error('您未认证或认证未通过，无法参加该活动')
            }
          })
          .catch((err) => {
            this.$message.error(err.message)
          })
      }
    },

    async sureSignUp() {
      try {
        const res = await yqmsActivityApply(this.id)
        if (res.success && res.code == 200) {
          this.$message.success(res.result)
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  background: #f1f1f1;
  padding: 50px 0;
  > div {
    width: 1200px;
    background: #fff;
    margin: auto;
  }

  .title {
    border-bottom: 1px solid #e5e5e5;
    padding-right: 30px;
    .horn-title-style {
      width: 160px;
      height: 55px;
      line-height: 55px;
      background-color: #1285d6;
      position: relative;
      font-size: 22px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: rgb(18, 133, 214);
        top: 100%;
        left: 50%;
        position: absolute;
        margin-left: -12px;
      }
    }
  }

  .substance {
    padding: 20px 0 0 0;
    border-bottom: 1px solid #e5e5e5;

    .survey {
      padding: 20px 30px;
      ul {
        margin: 0;
        li {
          color: #666;

          &:nth-child(3) {
            margin: 5px 0;
          }
        }
        .name {
          font-size: 16px;
          color: #333;
          margin-bottom: 20px;
          font-weight: bold;
        }
      }

      .btn-style {
        margin-top: 80px;
      }
    }
  }

  .concrete-content {
    padding: 30px;

    > div:nth-child(1) {
      margin-bottom: 20px;
    }

    .introduce {
      font-size: 16px;
      color: #333;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }

  .btn-style {
    width: 110px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    background: #1285d6;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
  }

  .example {
    text-align: center;
    padding: 200px 0;
  }
}
</style>
